
@import './base/core.sass';
#content {
  min-height: calc(100vh - 148px); }
#masthead {
  max-width: 854px !important;
  .masthead-icon {
    max-width: 422px;
    background-image: url("/assets/img/background/background@2x.png");
    background-size: 422px;
    background-position: center;
    height: 422px;
    position: relative;
    margin: -36px auto 0 auto;
    background-repeat: no-repeat;
    img {
      width: 268px;
      margin: 0 auto; } }
  .masthead-content {
    margin-top: -68px; } }
#culture {
  background-image: url("/assets/img/culture.png");
  background-repeat: no-repeat;
  background-position: top left;
  width: 780px;
  height: 340px;
  border-radius: 1rem;
  @include media-breakpoint-down(sm) {
    background-image: url("/assets/img/culture-mobile.png");
    width: 343px;
    height: 499px; }
  > div {
    height: 100%;
    padding-right: 54px;
    padding-left: 54px;
    @include media-breakpoint-down(sm) {
      padding: 0; } }
  .culture-item {
    line-height: 1.88; } }
#product {
  .product-item {
    margin-bottom: 40px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 24px; }
    .thumb {
      border-radius: 16px;
      box-shadow: 0 11px 35px 0 rgba(89, 141, 184, 0.15);
      background-color: #ffffff;
      padding: 23px;
      height: 141px;
      img {
        width: 95px;
        height: auto;
        @include media-breakpoint-down(sm) {
          width: 76px;
          height: auto; } } }
    .desc {
      padding-left: 16px;
      h5 {
        margin-bottom: 5px; }
      p {
        margin-bottom: 8px; }
      a {
        font-size: 12px; } } } }
.navbar {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  .navbar-brand {
    img {
      width: 145px; } } }
p, span {
  color: #696969; }
