
// Required
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/mixins";

$theme-colors: ( "primary": #332385, "secondary": #e66860, "danger": #ff4136, "red": #db2c21, "dark-primary": #372e64, "dark": #100d21, "primary-light": #7f71c6 );

$btn-border-radius:           99px;
$btn-border-radius-lg:        99px;
$btn-border-radius-sm:        99px;

@import "../bootstrap/root";
@import "../bootstrap/reboot";
@import "../bootstrap/type";
@import "../bootstrap/images";
@import "../bootstrap/code";
@import "../bootstrap/grid";
@import "../bootstrap/tables";
@import "../bootstrap/forms";
@import "../bootstrap/buttons";
@import "../bootstrap/transitions";
@import "../bootstrap/dropdown";
@import "../bootstrap/button-group";
@import "../bootstrap/input-group";
@import "../bootstrap/custom-forms";
@import "../bootstrap/nav";
@import "../bootstrap/navbar";
@import "../bootstrap/card";
@import "../bootstrap/breadcrumb";
@import "../bootstrap/pagination";
@import "../bootstrap/badge";
@import "../bootstrap/jumbotron";
@import "../bootstrap/alert";
@import "../bootstrap/progress";
@import "../bootstrap/media";
@import "../bootstrap/list-group";
@import "../bootstrap/close";
@import "../bootstrap/toasts";
@import "../bootstrap/modal";
@import "../bootstrap/tooltip";
@import "../bootstrap/popover";
@import "../bootstrap/carousel";
@import "../bootstrap/spinners";
@import "../bootstrap/utilities";
@import "../bootstrap/print";

$i: 48;
@while $i > 11 {
  .fs-#{$i} {
    font-size: 1px * $i;
    line-height:  (1px * $i) + (((1px * $i) * 20) / 100); }
  $i: $i - 2; }

.fs-120 {
  font-size: 120px;
  line-height: auto; }

.text-grey {
  color: #4b555f; }

.text-grey-dark {
  color: #1e2b37; }

.text-blue {
  color: #1480de; }

.text-red {
  color: #d53a22; }
